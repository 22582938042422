export const MADAME = 'madame'
export const MISTER = 'mister'

export const madamCivilityLabel = 'profile.personalInfos.civility.madam'
export const misterCivilityLabel = 'profile.personalInfos.civility.sir'

export const civilities = [
  {
    label: '',
    value: MADAME,
    data: MADAME,
  },
  {
    label: '',
    value: MISTER,
    data: MISTER,
  },
]
export const getCivility = (value) =>
  value === MADAME ? madamCivilityLabel : misterCivilityLabel
